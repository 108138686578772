import { useLocation } from 'react-router-dom'
import moment from 'moment'
import Bugsnag from '@bugsnag/js'
import React from 'react'

/**
 * A custom hook that builds on useLocation to parse the query string
 */
export const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

/**
 * Pipe functions
 * @param fns
 */
export const pipe =
  (...fns) =>
  x =>
    fns.reduce((v, f) => f(v), x)

/**
 * Zustand middleware to log state changes
 * @param config
 */
export const log = config => (set, get, api) =>
  config(
    args => {
      console.log('  applying', args)
      set(args)
      console.log('  new state', get())
    },
    get,
    api
  )

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * To add delays to requests in order to test the UI
 * @param ms
 */
export const delay = ms => {
  return async value => {
    await sleep(ms)
    return value
  }
}

export const titleCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const capitalize = (str: string | null) => {
  if (!str) {
    return str
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

// https://stackoverflow.com/a/44078785
export const uniqueID = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2)
}

/**
 * Compare two one level objects
 *
 * @param o1
 * @param o2
 */
export const compareSingleLevelObjects = function (o1: object, o2: object): boolean {
  if (o1 === o2) {
    return true
  }

  if (o1 === undefined || o2 === undefined) {
    return false
  }

  for (let p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false
      }
    }
  }

  for (let p in o2) {
    if (o2.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false
      }
    }
  }

  return true
}

export const deepCompare = (prevProps, nextProps): boolean => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps)
}

export const sortByDateDesc = field => {
  return (a, b) => {
    return moment.utc(b[field]).diff(moment.utc(a[field]))
  }
}

export const isObjectEmpty = (obj): boolean => {
  if (!obj) return true

  return Object.keys(obj).length === 0
}

export const chunk = (arr: any[], len: number): any[] => {
  let chunks = [],
    i = 0,
    n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }

  return chunks
}

export const getUrl = (path: string = '/') => {
  let host = window.location.host
  const protocol = window.location.protocol

  return `${protocol}//${host}${path}`
}

// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
export function copyToClipboard(text) {
  // @ts-ignore
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    // @ts-ignore
    return window.clipboardData.setData('Text', text)
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    let textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return prompt('Copy to clipboard: Ctrl+C, Enter', text)
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

/**
 * Notify of an error or message
 *
 * @param err
 * @param metadata
 */
export const notifyAndLog = (err: Error | string, metadata: { [key: string]: any } = {}) => {
  console.error(err, metadata)
  if (process.env.REACT_APP_BUGSNAG_API_KEY) {
    Bugsnag.notify(err, event => {
      event.addMetadata('metadata', metadata)
    })
  }
}
