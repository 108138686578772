import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './i18n'
import AuthProvider from './components/AuthProvider'
import App from './app'
import './scss/global.scss'

const AppWithProviders = () => {
  return (
    <Suspense fallback={<div />}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Suspense>
  )
}

const refreshKey = 'page-has-been-force-refreshed'

const retryPageLoad = () => {
  if (!window?.sessionStorage) return
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.sessionStorage.getItem(refreshKey) || 'false') as boolean

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.sessionStorage.setItem(refreshKey, 'true')
    return window.location.reload()
  } else {
    window.sessionStorage.setItem(refreshKey, 'false')
  }
}

const onError = event => {
  for (let error of event?.errors) {
    if (error.errorClass === 'ChunkLoadError') {
      retryPageLoad()

      return
    }
  }
}

const rootElement = document.getElementById('root')

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  })
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

  ReactDOM.render(
    <ErrorBoundary onError={onError}>
      <Suspense fallback={<div />}>
        <AppWithProviders />
      </Suspense>
    </ErrorBoundary>,
    rootElement
  )
} else {
  ReactDOM.render(<AppWithProviders />, rootElement)
}
