import { initializeApp } from 'firebase/app'
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from 'firebase/auth'
import * as firebaseui from 'firebaseui'

export const firebaseConfig = {
  apiKey: 'AIzaSyCoL8jjc4CRUA-uI9uMlScqKezrkYmyUQo',
  authDomain: 'geartrack-243919.firebaseapp.com',
  projectId: 'geartrack-243919',
  storageBucket: 'geartrack-243919.appspot.com',
  messagingSenderId: '190374997477',
  appId: '1:190374997477:web:7acddd81aafd998271d30c',
  measurementId: 'G-GMSYVMXW11',
}

/**
 * Due to domain containers recently implemented by browsers to increase privacy
 * Firebase has some issues when using the redirect login flow
 *
 * https://github.com/firebase/firebase-js-sdk/issues/6716
 * Starting June 24 2024, implementing one of the options will be required for redirect sign-in to work on Google Chrome M115+.
 * This is already required on Firefox 109+ and Safari 16.1+.
 */
export function shouldUsePopupFlow() {
  return true
}

export const firebaseUIConfig: firebaseui.auth.Config = {
  // Due to Enhanced Tracking Protection, firebase auth with oauth redirect doesn't work well on firefox
  // https://github.com/firebase/firebase-js-sdk/issues/6443
  signInFlow: shouldUsePopupFlow() ? 'popup' : 'redirect',
  signInSuccessUrl: '/login/complete', // will sync the local packages with the remote
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    FacebookAuthProvider.PROVIDER_ID,
    EmailAuthProvider.PROVIDER_ID,
    GithubAuthProvider.PROVIDER_ID,
    TwitterAuthProvider.PROVIDER_ID,
  ],
  tosUrl: '/terms-and-conditions',
  privacyPolicyUrl: '/privacy-policy',
}

// Initialize Firebase
export const firebaseInstance = initializeApp(firebaseConfig)

// export const perfInstance = getPerformance(firebaseInstance)

// cache data in indexdb (offline functionality)
// enableIndexedDbPersistence(getFirestore(firebaseInstance)).catch(err => {
//   if (err.code === 'failed-precondition') {
//     console.error('Multiple tabs open, persistence can only be enabled in one tab at a time.', err)
//   } else if (err.code === 'unimplemented') {
//     console.error('The current browser does not support all of the features required to enable persistence', err)
//   }
// })

// needs to be after self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
// export const appCheckInstance = initializeAppCheck(firebaseInstance, {
//   provider: new ReCaptchaV3Provider('6Lc0hDchAAAAAKA8TKm6XniMxGRksmN-mZJBCIg_'),
//   isTokenAutoRefreshEnabled: true,
// })
