import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import './dark-mode'

import { PrivateRoute } from './components/PrivateRoute'

const AppNavbarLazy = React.lazy(() => import('./components/Navbar/NavBar'))
const FooterLazy = React.lazy(() => import('./components/Footer'))

// Pages
const HomePageLazy = React.lazy(() => import('./pages/HomePage/HomePage'))
const LandingPageLazy = React.lazy(() => import('./pages/HomePage/LandingPage/LandingPage'))
const RoadmapPageLazy = React.lazy(() => import('./pages/RoadmapPage/RoadmapPage'))
const CarriersPageLazy = React.lazy(() => import('./pages/CarriersPage/CarriersPage'))
const NotFoundPageLazy = React.lazy(() => import('./pages/NotFoundPage/NotFoundPage'))
const LoginPageLazy = React.lazy(() => import('./pages/LoginPage/LoginPage'))
const TermsAndConditionsPageLazy = React.lazy(() => import('./pages/TermsAndConditionsPage'))
const PrivacyPolicyPageLazy = React.lazy(() => import('./pages/PrivacyPolicyPage'))
const SettingsPageLazy = React.lazy(() => import('./pages/SettingsPage/SettingsPage'))
const LoginCompleteLazy = React.lazy(() => import('./pages/LoginPage/LoginComplete'))
const ToastContainerLazy = React.lazy(() => import('./components/ToastContainer'))
const ImportPackagesFromOldLazy = React.lazy(() => import('./pages/ImportPackagesFromOld'))
const PackageShareLazy = React.lazy(() => import('./pages/PackageShare/PackageShare'))

const App = () => {
  return (
    <Router>
      <AppNavbarLazy />

      <div className="container pt-3">
        <div className="page-content">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePageLazy />} />
              <Route path="/share/:id" element={<PackageShareLazy />} />
              <Route path="/migrate" element={<ImportPackagesFromOldLazy />} />
              <Route path="/roadmap" element={<RoadmapPageLazy />} />
              <Route path="/landing" element={<LandingPageLazy />} />
              <Route path="/carriers" element={<CarriersPageLazy />} />
              <Route path="/settings" element={<SettingsPageLazy />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditionsPageLazy />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPageLazy />} />
              <Route path="/login/complete" element={<LoginCompleteLazy />} />
              <Route path="/login" element={<PrivateRoute element={<LoginPageLazy />} inverse={true} />} />

              {/* User Routes */}
              {/*<Route path="/profile" element={<PrivateRoute element={<UserProfilePageLazy />} />} />*/}

              <Route path="*" element={<NotFoundPageLazy />} />
            </Routes>
          </Suspense>
        </div>
      </div>

      <FooterLazy />
      <ToastContainerLazy />
    </Router>
  )
}

export default App
