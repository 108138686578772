import { User } from 'firebase/auth'
import { doc, getDoc, runTransaction, serverTimestamp } from 'firebase/firestore'
import PackageModel, { PackagesMap } from '@geartrack/shared-lib/dist/PackageModel'
import { mapObject } from '@geartrack/shared-lib/dist/utils'
import { firestoreInstance } from './firestore'
import { UserDocument } from '@geartrack/shared-lib/dist/firebase'
import UserModel from '@geartrack/shared-lib/dist/User'

const firestore = () => firestoreInstance

// Refs
export const userDocRef = (uid: string) => doc(firestore(), 'users', uid)

/*
|--------------------------------------------------------------------------
| Documents
|--------------------------------------------------------------------------
*/
export const getUser = async (uid: string): Promise<UserModel> => {
  const docRef = userDocRef(uid)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    // @ts-ignore
    return UserModel.fromDoc({ id: docSnap.id, ...docSnap.data() } as UserDocument)
  }

  return null
}

/**
 * Login / Create user
 *
 * Merge packages arrays
 * Usually used to merge local and remote packages
 *
 * We give the remote packages' priority because they could be changed by another instance
 */
export const upsertUserFromLogin = (user: User, localPackages: PackagesMap) => {
  const userRef = userDocRef(user.uid)

  return runTransaction(firestore(), async transaction => {
    const userDoc = await transaction.get(userRef)

    if (!userDoc.exists()) {
      // user creation
      transaction.set(
        userRef,
        {
          id: user.uid,
          email: user.email,
          avatar: user.photoURL ?? null,
          name: user.displayName,
          providerId: user.providerData[0]?.providerId,
          packages: mapObject<PackageModel>(localPackages, pkg => pkg.serializeToFirestoreDocument()),
          createdAt: serverTimestamp(),
        },
        { merge: true }
      )
    } else {
      // Update info during login
      const docData = userDoc.data()
      let remotePackages: PackagesMap = {}

      if (docData.packages) {
        // map from firestore format to PackageModel
        remotePackages = mapObject<PackageModel, PackageModel>(docData.packages, pkg =>
          PackageModel.fromFirestoreObject(pkg)
        )
      }

      const mergeResult = mergePackagesResult(localPackages, remotePackages)

      transaction.set(
        userRef,
        {
          email: user.email ?? docData.email,
          avatar: user.photoURL ?? docData.avatar,
          name: user.displayName ?? docData.name,
          providerId: user.providerData[0]?.providerId,
          packages: mapObject<PackageModel>(mergeResult, pkg => pkg.serializeToFirestoreDocument()),
          lastLoginAt: serverTimestamp(),
        },
        { merge: true }
      )
    }
  })
}

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
*/
/**
 * Merge packages arrays
 * Usually used to merge local and remote packages
 *
 * We give the remote packages' priority because they could be changed by another instance
 * @param leftPackages
 * @param rightPackages
 */
const mergePackagesResult = (leftPackages: PackagesMap, rightPackages: PackagesMap): PackagesMap => {
  const mergedPackages = Object.assign({}, leftPackages)

  // update existing ones or add new ones
  for (let key in rightPackages) {
    mergedPackages[key] = rightPackages[key]
  }

  return mergedPackages
}
