import PackageModel, { PackagesMap } from './PackageModel'
import { mapObject } from './utils'
import { UserDocument } from './firebase'

/**
 * User business model
 */
export default class UserModel {
  constructor(
    public id: string,
    public name?: string,
    public email?: string,
    public avatar?: string,
    public createdAt?: Date,
    public packages?: PackagesMap
  ) {}

  static fromDoc(doc: UserDocument) {
    let mappedPackages
    if (doc.packages) {
      mappedPackages = mapObject<PackageModel, PackageModel>(doc.packages, pkg => PackageModel.fromFirestoreObject(pkg))
    }
    return new UserModel(doc.id, doc.name, doc.email, doc.avatar, (doc.createdAt as any).toDate(), mappedPackages)
  }

  getName() {
    if (this.name) {
      return this.name
    }

    if (this.email) {
      return this.email.split('@')[0]
    }

    return this.id
  }
}
