export function mapObject<ItemType, ResultType = any>(
  obj: Record<string, ItemType>,
  mapperFn: (item: ItemType) => ResultType
): Record<string, ResultType> {
  const newObj: Record<string, ResultType> = {}

  for (let key in obj) {
    newObj[key] = mapperFn(obj[key])
  }

  return newObj
}
