import { useLayoutStore } from './state/layout.store'

const isDarkModeActive = useLayoutStore.getState().darkMode

const darkClass = 'theme-dark'

// Listening to dark mode changes

useLayoutStore.subscribe(
  // @ts-ignore
  state => state.darkMode,
  toggleBodyDarkTheme
)

// first page load, activate dark mode if needed
if (isDarkModeActive) {
  toggleBodyDarkTheme()
}

function toggleBodyDarkTheme() {
  if (document.body.classList.contains(darkClass)) {
    document.body.classList.remove(darkClass)
  } else {
    document.body.classList.add(darkClass)
  }
}
