import { fromDate } from './firebase'

export abstract class BaseModel {
  /**
   * Serialize this object into a plain object
   *
   * Custom logic to serialize certain types:
   *   Date -> number (milliseconds since epoch)
   */
  serializeToPlainObject() {
    const serialized: { [field: string]: any } = {}

    for (const key of Object.keys(this)) {
      // serialize Date
      if (this[key] instanceof Date) {
        serialized[key] = (this[key] as unknown as Date).getTime()
        continue
      }

      serialized[key] = this[key]
    }

    return serialized
  }

  /**
   * Convert the class object to a Firestore document
   *
   * Custom logic to serialize certain types:
   *   Date -> Timestramp.fromDate(date)
   *   undefined -> null
   */
  serializeToFirestoreDocument() {
    const serialized: { [field: string]: any } = {}

    for (const key of Object.keys(this)) {
      // serialize Date
      if (this[key] instanceof Date) {
        serialized[key] = fromDate(this[key] as unknown as Date)
        continue
      }

      if (this[key] === undefined) {
        serialized[key] = null
        continue
      }

      serialized[key] = this[key]
    }

    return serialized
  }
}
