import { addDoc, collection, connectFirestoreEmulator, initializeFirestore, serverTimestamp } from 'firebase/firestore'
import { firebaseInstance } from './firebase'
import PackageModel from '@geartrack/shared-lib/dist/PackageModel'
import { CarrierSuggestionDocument } from '@geartrack/shared-lib/dist/firebase'

export const firestoreInstance = initializeFirestore(firebaseInstance, {
  experimentalAutoDetectLongPolling: true,
})

if (process.env.REACT_APP_FIREBASE_EMULATORS) {
  connectFirestoreEmulator(firestoreInstance, 'localhost', 8080)
}

/*
|--------------------------------------------------------------------------
| Suggest new Carriers
|--------------------------------------------------------------------------
*/
export const suggestNewCarrier = (pkg: PackageModel, trackingLink: string, observations?: string, email?: string) => {
  const ref = collection(firestoreInstance, 'carrier_suggestions')

  const toInsert: CarrierSuggestionDocument = {
    // @ts-ignore
    pkg: pkg.serializeToFirestoreDocument(),
    link: trackingLink,
    observations: observations ?? null,
    email: email ?? null,
    createdAt: serverTimestamp(),
  }

  return addDoc(ref, toInsert)
}
