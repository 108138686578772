import { BaseModel } from './BaseModel'

export const validRegexId: RegExp = /^[A-Za-z0-9-_ /]{4,40}$/
export const validRegexDescription: RegExp = /^[0-9A-Za-zÀ-ÖØ-öø-ÿ-_+: ]+$/
export const validRegexPostalcode: RegExp = /[\w-]+/

export default class PackageModel extends BaseModel {
  constructor(
    public computedKey: string,
    public trackingId: string,
    public createdAt: Date,
    public description?: string,
    public extraData?: ExtraData,
    public collapsed?: boolean,
    public order?: number, // can be a ms timestamp (lastStateDate) or manual drag order 3,2,1.. (in reverse)
    public siteContext?: SiteContext
  ) {
    super()
  }

  static create(trackingId: string, description?: string, extraData: ExtraData = {}) {
    return new PackageModel(
      PackageModel.computeKey(trackingId, extraData),
      trackingId,
      new Date(),
      description,
      extraData
    )
  }

  static computeKey(trackingId: string, extraData?: ExtraData) {
    let key = `${trackingId}`

    if (extraData) {
      for (let param in extraData) {
        key += `_${param}-${extraData[param]}`
      }
    }

    return key.replace(/[\s#.:*!/\\|{}+()&^%$@`~,<>?ˆ´;"']/g, '')
  }

  static fromPlainObject(p: PackageModel) {
    return new PackageModel(
      p.computedKey,
      p.trackingId,
      new Date(p.createdAt),
      p.description,
      p.extraData,
      p.collapsed,
      p.order,
      p.siteContext
    )
  }

  static fromFirestoreObject(p: PackageModel) {
    return new PackageModel(
      p.computedKey,
      p.trackingId,
      // @ts-ignore
      p.createdAt?.toDate(),
      p.description,
      p.extraData,
      p.collapsed,
      p.order,
      p.siteContext
    )
  }

  /**
   * Return a new PackageModel object from the current one with the new
   * property values
   */
  edit(changes: Partial<PackageModel>): PackageModel {
    changes = Object.assign({}, this, changes)
    return new PackageModel(
      PackageModel.computeKey(this.trackingId, changes.extraData),
      this.trackingId, // can't change
      this.createdAt, // can't change
      changes.description,
      changes.extraData,
      changes.collapsed,
      changes.order,
      changes.siteContext
    )
  }

  /**
   * Returns a new Package instance if the value was updated
   * Otherwise returns the same instance
   * @param newOrderValue
   */
  updateOrder(newOrderValue: number): PackageModel {
    if (!newOrderValue) return this

    if (!this.order || newOrderValue > this.order) {
      return this.edit({ order: newOrderValue })
    }

    return this
  }

  getDescription() {
    return this.description ?? this.trackingId
  }

  isDescriptionEmpty() {
    return !this.description
  }

  getExtraDataString() {
    if (!this.extraData) return ''

    return Object.values(this.extraData).join(' ')
  }
}

export type ExtraData = Record<string, string>

export interface SiteContext {
  // suggested a new carrier form
  suggested?: boolean | string
}

// computedKey -> PackageModel
export type PackagesMap = Record<string, PackageModel>

export const getPackagesFromMap = (
  map: PackagesMap,
  sortFn?: (a: PackageModel, b: PackageModel) => number
): PackageModel[] => {
  const values = Object.values(map)

  if (sortFn) {
    return values.sort(sortFn)
  }

  return values
}
