import create from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware'
import PackageModel from '@geartrack/shared-lib/dist/PackageModel'

type ILayoutState = {
  darkMode: boolean
  toggleDarkMode: () => void

  packageModalIsVisible: boolean
  packageModalEditPackage: PackageModel | null // null = new package
  packageModalStartPackage: string // add tracking id from homepage
  packageModalOpen: (pkg: PackageModel | null) => void
  packageModalOpenAndStart: (trackingId: string) => void
  packageModalClose: () => void

  packageShareModal: PackageModel | null
  packageShareModalToggle: (pkg: PackageModel | null) => void

  carrierSuggestModal: PackageModel | null
  carrierSuggestModalToggle: (pkg: PackageModel | null) => void

  logoutModalIsVisible: boolean
  logoutModalOpen: () => void
  logoutModalClose: () => void
}

const state = (set): ILayoutState => ({
  darkMode: false,
  toggleDarkMode: () => set(state => ({ darkMode: !state.darkMode })),

  packageModalIsVisible: false,
  packageModalEditPackage: null,
  packageModalOpen: pkg => {
    set(() => ({
      packageModalIsVisible: true,
      packageModalEditPackage: pkg,
    }))
  },
  packageModalStartPackage: null,
  packageModalOpenAndStart: trackingId => {
    set(() => ({
      packageModalIsVisible: true,
      packageModalStartPackage: trackingId,
    }))
  },
  packageModalClose: () =>
    set(() => ({
      packageModalIsVisible: false,
      packageModalEditPackage: null,
    })),

  logoutModalIsVisible: false,
  logoutModalOpen: () => set(() => ({ logoutModalIsVisible: true })),
  logoutModalClose: () => set(() => ({ logoutModalIsVisible: false })),

  packageShareModal: null,
  packageShareModalToggle: (pkg: PackageModel | null) => {
    set(() => ({
      packageShareModal: pkg,
    }))
  },

  carrierSuggestModal: null,
  carrierSuggestModalToggle: (pkg: PackageModel | null) => {
    set(() => ({
      carrierSuggestModal: pkg,
    }))
  },
})

export const useLayoutStore = create<ILayoutState>()(
  subscribeWithSelector(
    persist(
      immer(
        devtools(state, {
          name: 'layoutStore',
        })
      ),
      {
        name: 'layout',
        getStorage: () => localStorage,
        partialize: state =>
          // @ts-ignore
          Object.fromEntries(Object.entries(state).filter(([key]) => key === 'darkMode')),
      }
    )
  )
)
