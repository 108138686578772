import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import UserModel from '@geartrack/shared-lib/dist/User'
import { onAuthStateChanged, User } from 'firebase/auth'
import CustomSpinner from './CustomSpinner'
import { onSnapshot } from 'firebase/firestore'
import { getUser, userDocRef } from '../libraries/users'
import { authInstance } from '../libraries/firebaseAuth'
import { notifyAndLog } from '../libraries/utils'
import { toast } from 'react-toastify'
import { UserDocument } from '@geartrack/shared-lib/dist/firebase'

interface IAuthContext {
  authUser?: User // set if signed in
  user?: UserModel // set if signed in
}

const AuthContext = createContext<IAuthContext>({})

/**
 * There are pages that shouldn't wait for the user document
 * to be loaded, the authUser is enough for them
 *
 * We don't want to show a loading state in that case
 */
const disableLoadingForPaths = ['/login/complete']

const AuthProvider = props => {
  const [authUser, setAuthUser] = useState<User>(undefined)
  const [user, setUser] = useState<UserModel>(null)

  // firebase user object listener
  useEffect(() => {
    // try loading and setting the authUser from localStorage
    // sometimes the firebase onAuthStateChanged callback isn't called
    try {
      let uA = window?.localStorage?.getItem('userAuth')
      if (uA) {
        uA = JSON.parse(uA)
        setAuthUser(uA as any)
      }
    } catch (e) {
      console.error(e)
    }

    return onAuthStateChanged(authInstance, user => {
      if (user) {
        // User is signed in
        setAuthUser(user)

        try {
          window?.localStorage?.setItem('userAuth', JSON.stringify(user))
        } catch (e) {
          console.error(e)
        }
      } else {
        // User is signed out
        setAuthUser(null)
        clearUserAuthLocalStorage()
      }
    })
  }, [])

  // load the user document from firestore (subscribed)
  useEffect(() => {
    if (!authUser?.uid) return

    return onSnapshot(
      userDocRef(authUser.uid),
      doc => {
        if (doc.exists()) {
          setUser(UserModel.fromDoc({ id: doc.id, ...doc.data() } as UserDocument))
        } else {
          // authInstance.signOut()
          // clearUserAuthLocalStorage()
        }
      },
      error => {
        notifyAndLog(error)
        // setUser(null)
        clearUserAuthLocalStorage()
      }
    )
  }, [authUser?.uid])

  // handle logout
  useEffect(() => {
    if (!authUser && user) {
      setUser(null)
      clearUserAuthLocalStorage()
    }
  }, [authUser, user])

  const contextValues: IAuthContext = useMemo(
    () => ({
      user,
      authUser,
    }),
    [user, authUser]
  )

  const isDisabledPath = disableLoadingForPaths.includes(window.location.pathname)
  const isLoading = isDisabledPath ? false : authUser === undefined || (authUser && !user)

  // Firebase seems to be unreliable sometimes
  // Loading auth and user document can timeout
  // Here we try fetching the user doc after 6s or logout if we don't have any auth
  useEffect(() => {
    const clearUser = () => {
      notifyAndLog('Loading user for more than 6s', {
        authUser,
        user,
        isDisabledPath,
      })
      console.log('Timer expired, still loading')
      authInstance.signOut()
      setAuthUser(null)
      clearUserAuthLocalStorage()
      toast.warn('You need to sign in again.')
    }

    const timer = setTimeout(() => {
      if (isLoading) {
        // try to load the user document one last time
        if (authUser?.uid) {
          console.log('One last read try')
          getUser(authUser.uid)
            .then(user => {
              // we have a user, loading state should disappear
              setUser(user)
            })
            .catch(e => {
              // logout
              notifyAndLog(e)
              clearUser()
            })
        } else {
          clearUser()
        }
      }
    }, 6000)

    return () => {
      clearTimeout(timer)
    }
  }, [isLoading, authUser, user, isDisabledPath])

  return (
    <AuthContext.Provider value={contextValues}>
      {isLoading && <CustomSpinner />}
      {!isLoading && props.children}
    </AuthContext.Provider>
  )
}

function clearUserAuthLocalStorage() {
  try {
    window?.localStorage?.removeItem('userAuth')
  } catch (e) {
    console.error(e)
  }
}

export const useAuth = () => useContext<IAuthContext>(AuthContext)

export default AuthProvider
