import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

/**
 * Flags in SVG: https://github.com/lipis/flag-icons/blob/main/flags/4x3/pt.svg
 */

interface LanguageEntry {
  code: string
  description: string
}

export const LANGUAGES: LanguageEntry[] = [
  {
    code: 'en',
    description: 'English',
  },
  {
    code: 'pt',
    description: 'Português',
  },
  {
    code: 'es',
    description: 'Español',
  },
]

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .use(LanguageDetector)
  .init({
    ns: ['homepage', 'validation', 'layout', 'packages', 'login', 'settings'],
    defaultNS: 'layout',
    fallbackLng: 'en',
    supportedLngs: LANGUAGES.map(p => p.code),
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: expiration(), // 0 locally, 7 days when deployed
          // Cloudflare Pages Build Commit SHA, inserted during CI/CD
          // This will force a cache revalidate on every deploy
          defaultVersion: process.env.REACT_APP_GIT_COMMIT_SHA,
        },
      ],
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag'],
    },
    interpolation: {
      escapeValue: false, // not needed for react
    },
  })

function expiration() {
  if (process.env.REACT_APP_GIT_COMMIT_SHA) {
    return 7 * 24 * 60 * 60 * 1000 // 7 days
  }

  return 0 // reload everytime when testing locally
}

export default i18n
