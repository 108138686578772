import PackageModel, { PackagesMap } from './PackageModel'

export const functionsRegion = 'europe-west1'

let firestoreClientSDK
try {
  firestoreClientSDK = require('firebase/firestore')
} catch (er) {}

export const fromDate = (date: Date) => {
  if (firestoreClientSDK) {
    return firestoreClientSDK.Timestamp.fromDate(date)
  }

  throw Error('Not implemented besided client sdk')
}

/*
|--------------------------------------------------------------------------
| Documents
|--------------------------------------------------------------------------
*/
/**
 * /users
 */
export interface UserDocument {
  id: string
  email?: string
  avatar?: string
  name?: string
  providerId?: string
  createdAt: unknown // timestamp
  packages?: PackagesMap
}

/**
 * /carriers_suggestions
 */
export interface CarrierSuggestionDocument {
  pkg: PackageModel
  link: string
  observations?: string
  email?: string
  createdAt: unknown // timestamp
}
