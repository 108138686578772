import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './AuthProvider'

export const PrivateRoute = props => {
  const inverse = props.inverse ?? false
  const location = useLocation()
  const { authUser } = useAuth()

  // inverse = user must not exist
  if (inverse) {
    return authUser ? <Navigate to="/" /> : props.element
  }

  if (!authUser) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return props.element
}
